/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap&family=Montserrat&family=Montserrat:ital,wght@0,800;1,800&display=swap&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap&family=Bebas+Neue&display=swap&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:wght@300;400;500;700&display=swap');

html {
  height: 100%;
  background-color: #F4F6F8;
}

body {
  height: 100%;
  background-color: #F4F6F8;
  font-family: "Montserrat", sans-serif;
  /* margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

#root {
  /* height: 100%; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F4F6F8;
}

/* hide incrment/decrement arrows of input tag */
.hidden-number-input::-webkit-outer-spin-button,
.hidden-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  appearance: none;
  -moz-appearance: textfield !important;

}


.font-bebas-neue {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
}

.font-lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}


/* Chrome, Safari and Opera  */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox  */
}