.getNumber-container {
    background-color: #a3a3a34d;
    height: 100vh;
    width: 100%;
    inset: 0;
    z-index: 1400 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.getNumber-bg-close {
    height: 100%;
    width: 100%;
    z-index: 1300 !important;
    position: absolute;
}

.getNumber-data {
    background-color: white;
    width: 50%;
    padding: 1.75rem;
    border-radius: 0.35rem;
    z-index: 1350 !important;
}

.getNumber-head {
    font-weight: 600;
    font-size: 1.75rem;
}

.getNumber-input {
    padding-top: 1rem;
    width: 100%;
    outline: none;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid gray;
}

/* library react-phone-number-input classes */
.PhoneInputInput {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
}