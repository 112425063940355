.advanced-selection {
	/* padding-left: 10px;
	padding-right: 10px; */
}
.advanced-selection__main {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.advanced-selection__main h5 {
	margin: 0;
	padding: 0;
}
.selection-by-date {
	margin-right: 10px;
	margin-left: 20px;
}
.advanced-selection-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.advanced-selection-content select,
input {
	padding: 7px;
	border-radius: 5px;
}
.advanced-selection-content h6 {
	padding: 0;
	margin: 0;
	margin-bottom: 5px;
}
.counter {
	margin-right: 10px;
}
.datepickers {
	display: flex;
}
.datepicker {
	margin-right: 15px;
}
.searchBarWrapper {
	padding-left: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* border: 1px solid gray; */
	width: 30%;
	margin-bottom: 5px;
	border-radius: 50px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.searchBar {
	padding: 10px;
	width: 80%;
	border: none;
	outline: none;
	background: transparent;
	padding: 10px;
}
.filterWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

/* //User plan tags */

.plans-selection-tags {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
}

.userplan {
	display: inline-block;
	width: 100px;
	height: auto;
	border-radius: 20px;
	background-color: white;
	color: black;
	padding: 10px;
	margin-right: 5px;
	text-align: center;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.userplan:hover {
	cursor: pointer;
}
