
.dccdcdcd {
    color: #c7c8cb;
    /* #0000004d */
}

/* custom vertical minimal scrollbar */

.minimal-y-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.minimal-y-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
}

.minimal-y-scrollbar::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 3px;
}

.minimal-y-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #838383;
}

/* ----------- */


 /* custom horizontal minimal scrollbar */

 .minimal-x-scrollbar::-webkit-scrollbar {
    /* width: 6px; */
    height: 6px;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 3px;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #838383;
  }

/* ----------- */
