@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap&family=Montserrat&family=Montserrat:ital,wght@0,800;1,800&display=swap&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap&family=Bebas+Neue&display=swap&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:wght@300;400;500;700&display=swap);
/* Google Fonts Import */

html {
  height: 100%;
  background-color: #F4F6F8;
}

body {
  height: 100%;
  background-color: #F4F6F8;
  font-family: "Montserrat", sans-serif;
  /* margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

#root {
  /* height: 100%; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F4F6F8;
}

/* hide incrment/decrement arrows of input tag */
.hidden-number-input::-webkit-outer-spin-button,
.hidden-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  appearance: none;
  -moz-appearance: textfield !important;

}


.font-bebas-neue {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
}

.font-lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}


/* Chrome, Safari and Opera  */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox  */
}
.admin_advanced-selection__Z-s4m {
	/* padding-left: 10px;
	padding-right: 10px; */
}
.admin_advanced-selection__main__1Xdv7 {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.admin_advanced-selection__main__1Xdv7 h5 {
	margin: 0;
	padding: 0;
}
.admin_selection-by-date__2OZZM {
	margin-right: 10px;
	margin-left: 20px;
}
.admin_advanced-selection-content__Mq4Ce {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.admin_advanced-selection-content__Mq4Ce select,
input {
	padding: 7px;
	border-radius: 5px;
}
.admin_advanced-selection-content__Mq4Ce h6 {
	padding: 0;
	margin: 0;
	margin-bottom: 5px;
}
.admin_counter__2tlbE {
	margin-right: 10px;
}
.admin_datepickers__3v361 {
	display: flex;
}
.admin_datepicker__1PnVY {
	margin-right: 15px;
}
.admin_searchBarWrapper__bjs6K {
	padding-left: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* border: 1px solid gray; */
	width: 30%;
	margin-bottom: 5px;
	border-radius: 50px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.admin_searchBar__3eyfY {
	padding: 10px;
	width: 80%;
	border: none;
	outline: none;
	background: transparent;
	padding: 10px;
}
.admin_filterWrapper__2uqDn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

/* //User plan tags */

.admin_plans-selection-tags__3nvS2 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
}

.admin_userplan__1BjjC {
	display: inline-block;
	width: 100px;
	height: auto;
	border-radius: 20px;
	background-color: white;
	color: black;
	padding: 10px;
	margin-right: 5px;
	text-align: center;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.admin_userplan__1BjjC:hover {
	cursor: pointer;
}

.getNumber-container {
    background-color: #a3a3a34d;
    height: 100vh;
    width: 100%;
    inset: 0;
    z-index: 1400 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.getNumber-bg-close {
    height: 100%;
    width: 100%;
    z-index: 1300 !important;
    position: absolute;
}

.getNumber-data {
    background-color: white;
    width: 50%;
    padding: 1.75rem;
    border-radius: 0.35rem;
    z-index: 1350 !important;
}

.getNumber-head {
    font-weight: 600;
    font-size: 1.75rem;
}

.getNumber-input {
    padding-top: 1rem;
    width: 100%;
    outline: none;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid gray;
}

/* library react-phone-number-input classes */
.PhoneInputInput {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
}

.dccdcdcd {
    color: #c7c8cb;
    /* #0000004d */
}

/* custom vertical minimal scrollbar */

.minimal-y-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.minimal-y-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
}

.minimal-y-scrollbar::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 3px;
}

.minimal-y-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #838383;
}

/* ----------- */


 /* custom horizontal minimal scrollbar */

 .minimal-x-scrollbar::-webkit-scrollbar {
    /* width: 6px; */
    height: 6px;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 3px;
  }

  .minimal-x-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #838383;
  }

/* ----------- */

